import React, { useId } from 'react'
import { Modal } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'

interface IProps {
  title: string
  negativeAnswer?: string
  positiveAnswer: string
  type: 'action' | 'confirmation'
  show: boolean
  onSubmit: () => void
  onHide?: () => void
}

const ModalAction = (props: IProps) => {
  const idKey = useId()
  return (
    <Modal {...props} centered key={idKey}>
      <Modal.Body className='position-relative h-auto'>
        <div className='d-flex flex-column align-items-center'>
          <img
            src={`${toAbsoluteUrl('/✅ Web - Mm/icons-56.png')}`}
            alt='e'
            height={60}
            width={60}
          />
          <h1 className='fs-2 px-5 text-center text-alert my-5'>{props.title}</h1>
        </div>
        <div className='row justify-content-evenly gap-5 mx-5 mt-5'>
          {props.type === 'action' ? (
            <React.Fragment>
              <button
                style={{ backgroundColor: '#E2F4FF', color: '#1C73CF' }}
                type='button'
                className='btn col'
                onClick={props.onHide}
              >
                {props.negativeAnswer}
              </button>
              <button
                style={{ background: '#0066CC', color: 'white' }}
                type='button'
                className='btn col'
                onClick={props.onSubmit}
              >
                {props.positiveAnswer}
              </button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <button style={{ background: '#0066CC', color: 'white' }} type='button' className='btn col-3' onClick={props.onSubmit}>
                {props.positiveAnswer}
              </button>
            </React.Fragment>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAction
