export const BackofficePermissionsEnum = {
  // ----- CLIENTES -----
  USER_CUSTOMER_ALL: [
    'user_customer_list',
    'user_customer_show',
    'user_customer_import',
    'user_customer_export',
    'user_customer_unlock',
    'user_customer_unlock_facephi',
    'user_customer_delete',
  ],
  USER_CUSTOMER_LIST: 'user_customer_list',
  USER_CUSTOMER_SHOW: 'user_customer_show',
  USER_CUSTOMER_IMPORT: 'user_customer_import',
  USER_CUSTOMER_EXPORT: 'user_customer_export', // --- Preguntar
  USER_CUSTOMER_UNLOCK: 'user_customer_unlock',
  USER_CUSTOMER_UNLOCK_FACEPHI: 'user_customer_unlock_facephi',
  USER_CUSTOMER_DELETE: 'user_customer_delete', // --- Preguntar

  // hay una opción de ver en columna Fondos, esto va a estar amarrado?

  // ----- FONDOS ------
  FUND_ALL: ['fund_list', 'fund_sync', 'fund_update', 'fund_change_status', 'fund_recommend'],
  FUND_LIST_ALL: ['fund_list', 'fund_sync', 'fund_update', 'fund_change_status'],
  FUND_LIST: 'fund_list',
  FUND_SYNC: 'fund_sync',
  FUND_UPDATE: 'fund_update', // configuracion
  FUND_CHANGE_STATUS: 'fund_change_status',
  FUND_RECOMMENDED: 'fund_recommend',

  // ----- MOVIMIENTOS -----
  MOVEMENT_ALL: [
    'subscription_list',
    'subscription_show',
    'rescue_list',
    'request_pending_list',
    'request_pending_update',
    'request_pending_cancel',
    'request_pending_spectrum',
    'request_canceled_list',
    'request_canceled_show',
  ],
  // Suscripciones
  SUBSCRIPTION_LIST: 'subscription_list',
  SUBSCRIPTION_SHOW: 'subscription_show', // FIXED: no tiene detalle - Preguntar -> Amarrar al número de operación y adjuntos

  // Rescates
  RESCUE_LIST: 'rescue_list',
  RESCUE_CHANGE_STATUS: 'rescue_change_status',

  // Solicitudes pendientes
  REQUEST_PENDING_LIST: 'request_pending_list',
  REQUEST_PENDING_UPDATE: 'request_pending_update',
  REQUEST_PENDING_CANCEL: 'request_pending_cancel',
  REQUEST_PENDING_SPECTRUM: 'request_pending_spectrum',
  REQUEST_PENDING_SHOW: 'request_pending_show',

  // Solicitudes anuladas
  REQUEST_CANCELED_LIST: 'request_canceled_list',
  REQUEST_CANCELED_SHOW: 'request_canceled_show', // no tiene detalle - Preguntar

  // ----- CONFIGURACION -----
  CONFIG_ALL: [
    'user_person_list',
    'user_person_show',
    'user_person_create',
    'user_person_update',
    'user_person_delete',
    'capability_role_list',
    'capability_role_show',
    'capability_role_create',
    'capability_role_update',
    'capability_role_delete',
    'user_prospect_list',
    'user_prospect_show',
    'user_prospect_update',
    'risk_tolerance_list',
    'risk_tolerance_update',
    'user_executive_list',
    'user_executive_show',
    'user_executive_sync',
    'user_executive_update',
    'user_executive_archive',
    'general_config_update',
  ],
  // Usuarios
  USER_PERSON_ALL: [
    'user_person_list',
    'user_person_show',
    'user_person_create',
    'user_person_update',
    'user_person_delete',
  ],
  USER_PERSON_LIST: 'user_person_list',
  USER_PERSON_SHOW: 'user_person_show',
  USER_PERSON_CREATE: 'user_person_create',
  USER_PERSON_UPDATE: 'user_person_update',
  USER_PERSON_DELETE: 'user_person_delete', // no hay delete, preguntar

  // Roles y permisos
  ROLE_ALL: [
    'capability_role_list',
    'capability_role_show',
    'capability_role_create',
    'capability_role_update',
    'capability_role_delete',
  ],
  ROLE_LIST: 'capability_role_list',
  ROLE_SHOW: 'capability_role_show',
  ROLE_CREATE: 'capability_role_create',
  ROLE_UPDATE: 'capability_role_update',
  ROLE_DELETE: 'capability_role_delete', // no hay delete, preguntar

  // prospectos
  USER_PROSPECT_ALL: ['user_prospect_list', 'user_prospect_show', 'user_prospect_update'],
  USER_PROSPECT_LIST: 'user_prospect_list',
  USER_PROSPECT_SHOW: 'user_prospect_show', // preguntar no hay opción de detalle
  USER_PROSPECT_UPDATE: 'user_prospect_update', // hay una opción más de Generar contraseña, preguntar si se va a separar con otro permiso

  // Tolerancia al riesgo
  RISK_TOLERANCE_LIST: 'risk_tolerance_list', // NEW
  RISK_TOLERANCE_UPDATE: 'risk_tolerance_update', // FIXED: solo existe 1, se debería tener 2? uno para ver y otro para el botón Editar

  // Lista asesoras
  USER_EXECUTE_ALL: [
    'user_executive_list',
    'user_executive_show',
    'user_executive_sync',
    'user_executive_update',
    'user_executive_archive',
  ],
  USER_EXECUTIVE_LIST: 'user_executive_list',
  USER_EXECUTIVE_SHOW: 'user_executive_show',
  USER_EXECUTIVE_SYNC: 'user_executive_sync',
  USER_EXECUTIVE_UPDATE: 'user_executive_update',
  USER_EXECUTIVE_ARCHIVE: 'user_executive_archive',

  // Configuracion general
  GENERAL_CONFIG_UPDATE: 'general_config_update', // falta consultar

  // Logger
  LOGGER_LIST: 'logger_list',
}
