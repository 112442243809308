import { useMutation } from '@tanstack/react-query'
import { formatDateTimeUTC, formatTimeToHHMMSS, getHHMMFormat } from '../../../../common/helpers/DateHandlers'
import { useStateCallback } from '../../../../common/hooks/useStateCallback'
import { ICustomerDetail } from '../../../../common/interfaces'
import { FileService } from '../../../../services/FileService'

interface IProps {
  customerDetail: ICustomerDetail
}

function getFileNameFromBucketPath(path: string) {
  return path.substring(path.lastIndexOf('/') + 1, path.length)
}

export const MovementsDocumentTable = ({ customerDetail }: IProps) => {
  const [fileUrl, setFileUrl] = useStateCallback('')
  const downloadMutation = useMutation({
    mutationFn: () => FileService().downloadFromAzure(fileUrl),
    onSuccess: async (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', getFileNameFromBucketPath(fileUrl))

      document.body.appendChild(link)
      link.click()

      await new Promise((resolve) => setTimeout(resolve, 1000))

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    },
  })
  return (
    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
      {customerDetail.movements.length ? (
        <>
          <thead>
            <tr className='fw-medium text-muted'>
              <th className='w-100px'>TIPO</th>
              <th className='w-100px'>TRASPASO</th>
              <th className='w-150px'>FECHA Y HORA</th>
              <th className='w-150px'>MONTO</th>
              <th className='w-150px'>PLATAFORMA</th>
              <th>FONDO</th>
              <th className='text-center'>ADJUNTO</th>
            </tr>
          </thead>
          <tbody>
            {customerDetail.movements
              .sort(
                (a, b) =>
                  new Date(b.fechaSolicitud).getTime() - new Date(a.fechaSolicitud).getTime()
              )
              .map((movement, index) => {
                const newDate = movement.fechaConstancia? new Date(movement.fechaConstancia): new Date(movement.fechaSolicitud)
                return (
                  <tr key={index}>
                    <td>
                      <div className='badge badge-light p-3'>{movement.tipoSolicitud}</div>
                    </td>

                    <td>
                      <span className='text-muted d-block fs-5'>
                        {movement.internal_transfer ? "Sí" : "No"}
                      </span>
                    </td>

                    <td>
                      <span className='text-muted d-block fs-5'>
                        {formatDateTimeUTC(newDate.toDateString())}
                        <span className='text-muted d-block fs-5'>
                          {formatTimeToHHMMSS(newDate)}
                        </span>
                      </span>
                    </td>
                    <td>
                      <span className='text-muted d-block fs-5'>
                        {movement.moneda === 'Dólares' ? '$' : 'S/.'}
                        {movement.montoNetoSolicitud.toFixed(4)}
                      </span>
                    </td>
                    <td>
                      <span className='text-muted d-block fs-5'>{movement.origin}</span>
                    </td>
                    <td>
                      <span className='text-muted d-block fs-5'>
                        {movement.descripFondo} - {movement.descripFondoSerie}
                      </span>
                    </td>
                    <td className={'text-center'}>
                      {movement.constancy_url ? (
                        <div className='d-flex align-items-center  p-1 justify-content-center cursor-pointer'>
                          {downloadMutation.isLoading && movement.constancy_url === fileUrl ? (
                            <div className='text-center text-muted'>
                              <div
                                className='spinner-border'
                                style={{
                                  width: '18px',
                                  height: '18px',
                                }}
                                role='status'
                              ></div>
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                if (downloadMutation.isLoading) {
                                  return
                                }
                                setFileUrl(movement.constancy_url!)
                                downloadMutation.mutate()
                              }}
                              className='iconButton'
                            >
                              <i className='bi bi-download fs-3 m-2'></i>
                            </div>
                          )}
                        </div>
                      ) : (
                        <span className='text-muted'>--</span>
                      )}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </>
      ) : (
        <span className='notFound text-muted text-muted fs-5 text-center'>
          No se encontraron movimientos para este cliente
        </span>
      )}
    </table>
  )
}
