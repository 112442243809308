/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@tanstack/react-query'
import { FC, useEffect } from 'react'
import {
  IRescueDetail,
  IRescueDetailResponse,
  ISelectedRescue,
  RescueStatusEnum,
} from '../../../../common/interfaces'
import { RescueService } from '../../../../services'

interface IProps {
  rescue_id: string | number
  current_rescue: number
  selectedRescues: ISelectedRescue[]
  setSelectedRescues: any
  money_type: string | boolean
  mutationSuccess: boolean
}

export const RescueDetailCollapsible: FC<IProps> = ({
  rescue_id,
  current_rescue,
  money_type,
  setSelectedRescues,
  selectedRescues,
  mutationSuccess,
}) => {
  const {
    isLoading,
    isError,
    data: rescue_details,
    isFetching,
    isRefetching,
    refetch,
  } = useQuery<IRescueDetailResponse>(
    ['rescue-detail', rescue_id],
    () => RescueService().listRescueDetails(rescue_id),
    {
      keepPreviousData: true,
      enabled: current_rescue === rescue_id,
    }
  )

  useEffect(() => {
    refetch()
  }, [mutationSuccess])

  return (
    <td colSpan={12} className='px-20 py-5'>
      <div className='rounded'>
        {((isLoading || isFetching) && !isError) || isRefetching ? (
          <div className='text-center m-5 p-10'>
            <div className='spinner-border' role='status'></div>
          </div>
        ) : (
          <>
            {rescue_details?.data && rescue_details.data.length !== 0 ? (
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 mb-0'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-120px'>NÚMERO SOLICITUD</th>
                    <th className='min-w-150px'>MONTO</th>
                    <th className='min-w-120px'>ESTADO</th>
                  </tr>
                </thead>
                <tbody>
                  {rescue_details?.data &&
                    rescue_details
                      ?.data!.sort()
                      .map((rescue_detail: IRescueDetail, index: number) => {
                        return (
                          <tr className='collapsible cursor-pointer' key={index}>
                            <td
                              style={{
                                paddingTop: '25px',
                                paddingBottom: '25px',
                              }}
                            >
                              <span className='text-muted d-block fs-5'>
                                {rescue_detail.request_number}
                              </span>
                            </td>
                            <td>
                              <span className='text-muted d-block fs-5'>
                                {money_type}
                                {rescue_detail.amount.toFixed(4)}
                              </span>
                            </td>
                            <td>
                              {rescue_detail.status === RescueStatusEnum.INPROGRESS ? (
                                <span className='badge badge-light-primary'>En proceso</span>
                              ) : rescue_detail.status === RescueStatusEnum.APPROVED ? (
                                <span className='badge badge-light-success'>Aprobado</span>
                              ) : rescue_detail.status === RescueStatusEnum.PENDING ? (
                                <span className='badge badge-light-warning'>Pendiente</span>
                              ) : (
                                rescue_detail.status === RescueStatusEnum.ANNULLED && (
                                  <span className='badge badge-light-danger'>Anulado</span>
                                )
                              )}
                            </td>
                          </tr>
                        )
                      })}
                </tbody>
              </table>
            ) : (
              <span className='notFound text-muted pt-5 pb-5 fs-5 text-center'>
                No se encontraron resultados
              </span>
            )}
          </>
        )}
      </div>
    </td>
  )
}
