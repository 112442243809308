import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { UpdatePasswordModal } from '../../common/components/UpdatePasswordModal'
import { BackofficePermissionsEnum as Permissions } from '../../common/enums/backOfficePermissionsEnum'
import { useDebounce } from '../../common/hooks/useDebounce'
import usePermissions from '../../common/hooks/usePermissions'
import { useStateCallback } from '../../common/hooks/useStateCallback'
import { BloquedClients } from './components/BloquedClients'
import { ClientsTable } from './components/ClientsTable'
import { ModalConfirm } from './components/ModalConfirm'
import { TocBloquedClients } from './components/TocBloquedClients'
import { FilterClients } from './FilterClients'
import { ActionModal } from '../../common/components/ActionModal'

export interface IFilters {
  start_date: string
  end_date: string
  fund?: string
  serie?: string
  risk_profile?: string
  data_treatment?: string
}

const initialValue = {
  start_date: '',
  end_date: '',
}

export const ListClients = () => {
  const [search, setSearch] = useStateCallback('')
  const debouncedSearch = useDebounce(search, 500)
  const [selectedUser, setSelectedUser] = useStateCallback<any>({})
  const [changedUser, setChangedUser] = useStateCallback(false)
  const [changedProspect, setChangedProspect] = useState([])
  const [selectedTab, setSelectedTab] = useStateCallback('clientes')
  const [filters, setFilters] = useStateCallback<IFilters>(initialValue)
  const bloquedRef = useRef<any>()
  const tocBloquedRef = useRef<any>()
  const { hasPermission } = usePermissions()

  return (
    <React.Fragment>
      <ul className='nav nav-tabs position-relative nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
        {hasPermission(Permissions.USER_CUSTOMER_LIST) && (
          <li className='nav-item'>
            <a
              className='nav-link active'
              onClick={() => setSelectedTab('clientes')}
              data-bs-toggle='tab'
              href='#kt_tab_pane_4'
            >
              {' '}
              <span className='nav-icon'>
                <i className=' bi-person-lines-fill fs-3 m-2'></i>
              </span>
              Listado de clientes
            </a>
          </li>
        )}
        {hasPermission(Permissions.USER_CUSTOMER_UNLOCK) && (
          <li className='nav-item'>
            <a
              className='nav-link'
              onClick={() => setSelectedTab('bloqued')}
              data-bs-toggle='tab'
              href='#kt_tab_pane_5'
            >
              {' '}
              <span className='nav-icon'>
                <i className=' bi-person-slash fs-3 m-2'></i>
              </span>
              Clientes bloqueados
            </a>
          </li>
        )}
        {hasPermission(Permissions.USER_CUSTOMER_UNLOCK_FACEPHI) && (
          <li className='nav-item'>
            <a
              className='nav-link'
              data-bs-toggle='tab'
              onClick={() => setSelectedTab('toc_bloqued')}
              href='#prospects_to_custumer'
            >
              {' '}
              <span className='nav-icon'>
                <i className='bi-person-bounding-box fs-3 m-2'></i>
              </span>
              Bloqueos por TOC
            </a>
          </li>
        )}

        {(selectedTab === 'bloqued' || selectedTab === 'toc_bloqued') && (
          <div className='border-0 position-absolute row mb-0' style={{ right: 0 }}>
            <div className='col text-end mb-0'>
              <button
                className='text-end btn p-2 d-flex justify-content-center align-items-center'
                onClick={() => {
                  if (selectedTab === 'bloqued') {
                    bloquedRef.current!.refreshList()
                  }
                  if (selectedTab === 'toc_bloqued') {
                    tocBloquedRef.current!.refreshList()
                  }
                }}
                style={{ background: '#eee' }}
              >
                <i className='bi bi-arrow-clockwise fs-2 p-0' style={{ color: '#5e6278' }}></i>
              </button>
            </div>
          </div>
        )}
      </ul>
      <div className='tab-content' id='myTabContent'>
        {hasPermission(Permissions.USER_CUSTOMER_LIST) && (
          <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
            <div className='card border-0 pt-5 border-radius-20px'>
              <div className='card'>
                <div className='card-header border-bottom-0 d-flex align-items-center'>
                  <div className='d-flex align-items-center gap-8'>
                    <div className='text-center'>
                      <input
                        className='form-control'
                        type='text'
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        placeholder='🔍︎  Buscador'
                        style={{
                          width: '100%',
                          minWidth: '320px',
                          backgroundColor: '#f9f9f9',
                          border: 0,
                          height: '40px',
                        }}
                        name='searchName'
                      />
                    </div>
                    <div
                      style={{ background: 'rgb(0, 123, 195)' }}
                      className='rounded p-2 d-flex justify-content-center align-items-center cursor-pointer'
                      data-bs-toggle='modal'
                      data-bs-target='#filter-subscriptions'
                    >
                      <i className='bi bi-filter fs-2 text-white'></i>
                    </div>
                    {filters !== initialValue && (
                      <span
                        style={{
                          borderBottom: '1px inset #a1a5b7',
                        }}
                        onClick={() => setFilters(initialValue)}
                        className='text-muted cursor-pointer d-block fs-6'
                      >
                        Limpiar filtros
                      </span>
                    )}
                  </div>

                  {hasPermission(Permissions.USER_CUSTOMER_IMPORT) && (
                    <Link to={'/client/import'} className='text-end mt-8'>
                      <button
                        style={{ background: 'rgb(0, 123, 195)' }}
                        className='btn text-white cursor-pointer'
                      >
                        <img
                          style={{
                            marginRight: '5px',
                          }}
                          src={toAbsoluteUrl('/✅ Web - Mm/upload.svg')}
                          alt='plus'
                          className='w-15px  '
                        />
                        Importar Clientes
                      </button>
                    </Link>
                  )}
                </div>

                <ClientsTable
                  setSelectedUser={setSelectedUser}
                  changedProspect={changedProspect}
                  changedUser={changedUser}
                  setSearch={setSearch}
                  search={search.length > 2 ? debouncedSearch! : ''}
                  urlEndpoint='/user/app/list'
                  filters={filters}
                  setFilters={setFilters}
                />
              </div>
            </div>
          </div>
        )}

        {hasPermission(Permissions.USER_CUSTOMER_UNLOCK) && (
          <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
            {selectedTab === 'bloqued' && <BloquedClients ref={bloquedRef} />}
          </div>
        )}

        {hasPermission(Permissions.USER_CUSTOMER_UNLOCK_FACEPHI) && (
          <div className='tab-pane fade' id='prospects_to_custumer' role='tabpanel'>
            <div className='card border-0 pt-5 border-radius-20px'>
              {selectedTab === 'toc_bloqued' && <TocBloquedClients ref={tocBloquedRef} />}
            </div>
          </div>
        )}
      </div>

      {selectedUser && (
        <UpdatePasswordModal
          setChangedProspect={setChangedProspect}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      )}

      <ModalConfirm
        id='Active'
        title='¿Está seguro que desea activar el cliente?'
        negativeAnswer='No, regresar'
        positiveAnswer='Sí, activar'
        confirmationId='#ActiveDelete'
        type='action'
        selectedValue={selectedUser}
        setChangedValue={setChangedUser}
        changedValue={changedUser}
        confirmation={true}
        confirmationTitle='activar'
        confirmationAnswer='Aceptar'
        updateStatusUrl='/user/app/change-status/'
      />

      <ModalConfirm
        id='Inactive'
        title='¿Está seguro que desea activar el cliente?'
        negativeAnswer='No, regresar'
        positiveAnswer='Sí, inactivar'
        confirmationId='#InactiveDelete'
        type='action'
        selectedValue={selectedUser}
        setChangedValue={setChangedUser}
        changedValue={changedUser}
        confirmation={true}
        confirmationTitle='inactivar'
        confirmationAnswer='Aceptar'
        updateStatusUrl='/user/app/change-status/'
      />

      <ActionModal
        id='Delete'
        title='¿Está seguro que desea eliminar el cliente?'
        negativeAnswer='No, regresar'
        positiveAnswer='Sí, eliminar'
        type='action'
        confirmationTitle='El usuario fue eliminado'
        deleteUrl='/user/app/delete/'
        archive={true}
        selectedValue={selectedUser}
        setChangedValue={setChangedUser}
        changedValue={changedUser}
      />

      <FilterClients setFilters={setFilters} filters={filters} />
    </React.Fragment>
  )
}
