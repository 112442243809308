/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BackofficePermissionsEnum as Permissions } from '../../../common/enums/backOfficePermissionsEnum'
import { usePaginator } from '../../../common/hooks/usePaginator'
import usePermissions from '../../../common/hooks/usePermissions'
import { useStateCallback } from '../../../common/hooks/useStateCallback'
import { IClient } from '../../../common/interfaces/user.interface'
import { ClientService } from '../../../services/ClientService'
import { formatCustomerFulllname } from '../helpers/customerFormatHelper'
import { IFilters } from '../ListClients'
import { ClientFundsModal } from './ClientFundsModal'

interface IProps {
  search: string
  setSelectedUser: React.Dispatch<React.SetStateAction<any>>
  changedUser: boolean
  setSearch: any
  urlEndpoint: string
  prospect?: boolean
  changedProspect: never[]
  filters?: IFilters
  setFilters?: (state: IFilters, cb?: ((state: IFilters) => void) | undefined) => void
}

const pageSizes = [20, 40, 60, 100]
export const ClientsTable = ({
  search,
  setSelectedUser,
  changedUser,
  setSearch,
  urlEndpoint,
  prospect,
  changedProspect,
  filters,
  setFilters,
}: IProps) => {
  const {
    limit,
    setPage,
    setLimit,
    paginationComponent,
    query: { data: clients, refetch, isLoading, isFetching, isError },
  } = usePaginator({
    initPage: 1,
    initLimit: pageSizes.at(0)!,
    pageSizes,
    search,
    paginatorOptions: {
      queryKey: [prospect ? 'prospect-list' : 'client-list'],
      fetch: ClientService().listClients,
    },
    filters: {
      ...filters,
      prospect,
    },
  })

  const [selectedClient, setSelectedClient] = useStateCallback<number>(0)
  const { hasPermission } = usePermissions()

  useEffect(() => {
    setPage(1)
    refetch()
  }, [JSON.stringify(filters), limit, changedUser, refetch])

  const cleanupRequest = () => {
    setPage(1)
    setLimit(20)
    setSearch('')
    setFilters &&
      setFilters(
        {
          start_date: '',
          end_date: '',
        },
        () => {
          refetch()
        }
      )
  }

  return (
    <React.Fragment>
      <div className='card-body'>
        <div className='table-responsive p-2'>
          {(isLoading || isFetching) && !isError ? (
            <div className='text-center m-5 p-10'>
              <div className='spinner-border' role='status'></div>
            </div>
          ) : (
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  {((hasPermission([Permissions.USER_CUSTOMER_SHOW, Permissions.USER_CUSTOMER_DELETE]) && !prospect) ||
                    (hasPermission(Permissions.USER_PROSPECT_UPDATE) && prospect)) && (
                    <th>ACCIONES</th>
                  )}
                  <th>CLIENTE</th>
                  <th>ALIAS</th>
                  <th className='min-w-150px'>Nº DOCUMENTO</th>
                  <th>CORREO</th>
                  <th className='min-w-150px'>SERIE ACTIVA</th>
                  <th className='min-w-150px'>ASESOR</th>
                  <th>ESTADO</th>
                  {!prospect && <th className='min-w-100px text-center'>FONDOS</th>}
                </tr>
              </thead>

              <tbody>
                {clients?.data
                  .filter((e: any) => {
                    if (!prospect) {
                      return e
                    }
                    return !changedProspect.includes(e.id as never)
                  })
                  .map((client: IClient, index: number) => {
                    return (
                      <tr key={index}>
                        {((hasPermission([Permissions.USER_CUSTOMER_SHOW, Permissions.USER_CUSTOMER_DELETE]) && !prospect) ||
                          (hasPermission(Permissions.USER_PROSPECT_UPDATE) && prospect)) && (
                          <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <div className='dropdown'>
                                <button
                                  className='btn btn-light'
                                  type='button'
                                  id='dropdownMenuButton'
                                  data-bs-toggle='dropdown'
                                  aria-haspopup='true'
                                  aria-expanded='false'
                                >
                                  <i className='fas p-0 fa-ellipsis-v'></i>
                                </button>
                                <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                                  {!prospect &&
                                    client.number_document &&
                                    client.name &&
                                    hasPermission(Permissions.USER_CUSTOMER_SHOW) && (
                                      <Link
                                        to={`/client/show/${client.id}`}
                                        className='dropdown-item text-secondary'
                                      >
                                        <i className='bi bi-eye m-2 text-secondary'></i>Ver detalle
                                      </Link>
                                    )}
                                  {(!prospect ||
                                    (prospect &&
                                      hasPermission(Permissions.USER_PROSPECT_UPDATE))) && (
                                    <>
                                      {['1', '0'].includes(client.status.toString()) && (
                                        <button
                                          type='button'
                                          data-bs-toggle='modal'
                                          data-bs-target='#UpdatePassword'
                                          className='dropdown-item text-secondary'
                                          onClick={() => setSelectedUser(client)}
                                        >
                                          <i className='bi bi-key m-2 text-secondary'></i>
                                          {prospect ? 'Generar' : 'Actualizar'} contraseña
                                        </button>
                                      )}
                                      {client.status.toString() === '1' ? (
                                        <button
                                          type='button'
                                          data-bs-toggle='modal'
                                          data-bs-target='#Inactive'
                                          className='dropdown-item text-danger'
                                          onClick={() => setSelectedUser(client)}
                                        >
                                          <i className='bi bi-lock m-2 text-danger'></i>Inactivar
                                        </button>
                                      ) : (
                                        client.status.toString() === '0' && (
                                          <button
                                            type='button'
                                            data-bs-toggle='modal'
                                            data-bs-target='#Active'
                                            onClick={() => setSelectedUser(client)}
                                            className='dropdown-item text-success'
                                          >
                                            <i className='bi bi-unlock m-2 text-success'></i>Activar
                                          </button>
                                        )
                                      )}
                                    </>
                                  )}
                                  {!prospect &&
                                    hasPermission(Permissions.USER_CUSTOMER_DELETE) && (
                                      <button
                                        type='button'
                                        data-bs-toggle='modal'
                                        data-bs-target='#Delete'
                                        className='dropdown-item text-danger'
                                        onClick={() => setSelectedUser(client)}
                                      >
                                        <i className='bi bi-trash m-2 text-danger'></i>Eliminar
                                      </button>
                                    )}
                                </div>
                              </div>
                            </div>
                          </td>
                        )}
                        <td>
                          {prospect ||
                          !client.number_document ||
                          !hasPermission(Permissions.USER_CUSTOMER_SHOW) ? (
                            <span className='text-dark text-muted fs-5 text-center'>
                              {formatCustomerFulllname(client)}
                            </span>
                          ) : (
                            <Link
                              to={`/client/show/${client.id}`}
                              className='text-dark text-muted text-hover-primary fs-5 text-center'
                            >
                              {formatCustomerFulllname(client)}
                            </Link>
                          )}
                        </td>
                        <td>
                          <span className='text-muted d-block fs-5'>{client.username || '--'}</span>
                        </td>
                        <td>
                          <span className='text-muted d-block fs-5'>
                            {client.number_document || '--'}
                          </span>
                        </td>
                        <td>
                          <span className='text-muted d-block fs-5'>{client.email || '--'}</span>
                        </td>

                        <td>
                          <span className='text-muted d-block fs-5'>
                            {client.serie_name || '--'}
                          </span>
                        </td>

                        <td>
                          <span className='text-muted d-block fs-5'>
                            {client.business_executive_fullname || '--'}
                          </span>
                        </td>

                        <td>
                          {client.status.toString() === '0' ? (
                            <span className='badge badge-light-danger'>Inactivo</span>
                          ) : client.status.toString() === '1' ? (
                            <span className='badge badge-light-success'>Activo</span>
                          ) : (
                            <span className='badge badge-light-warning'>Archivado</span>
                          )}
                        </td>
                        {/* {hasPermission(Permissions.USER_CUSTOMER_SHOW) && (
                          <> */}
                        {!prospect && (
                          <td className='d-flex justify-content-center'>
                            <button
                              data-bs-toggle='modal'
                              data-bs-target={`#Fund${client.id}`}
                              onClick={() => setSelectedClient(client.id)}
                              style={{
                                backgroundColor: '#f3f6f9',
                                color: '#728097',
                                border: '1px solid #72809711',
                              }}
                              className='btn btn-light py-2 px-4'
                            >
                              Ver
                            </button>
                            <ClientFundsModal
                              selectedClient={selectedClient}
                              title='Fondos invertidos'
                              docType={client.document_type}
                              numDoc={client.number_document}
                              id={`Fund${client.id}`}
                            />
                          </td>
                        )}
                        {/* </>
                        )} */}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          )}

          {isError && (
            <span className='notFound text-muted text-muted fs-5 text-center mt-5'>
              No se encontraron resultados
              <a
                className='link'
                style={{
                  marginLeft: 5,
                  cursor: 'pointer',
                }}
                onClick={cleanupRequest}
              >
                refrescar la tabla
              </a>
            </span>
          )}
          {clients?.data.length === 0 && !isLoading && (
            <span className='notFound text-muted text-muted fs-5 text-center mt-8 mb-8'>
              No se encontraron resultados
              <a
                className='link'
                style={{
                  marginLeft: 5,
                  cursor: 'pointer',
                }}
                onClick={cleanupRequest}
              >
                mostrar todos los usuarios
              </a>
            </span>
          )}
          {paginationComponent()}
        </div>
      </div>
    </React.Fragment>
  )
}
